<template>
  <div class="page page--no-header">
    <div class="container">
        <div class="row mb--sm--9 mb--md--20 mt--sm--9 mt--md--20">
            <div class="col col--sm--12 col--md--6 text--sm--center d--sm--flex justify-content--sm--center align-items--sm--center flex-direction--sm--column mb--sm--9 mb--md--0">
                <HeaderLogo class="mb--sm--2" style="color:#00867C; max-width: 250px"/>
                <!-- <div class="logo-branch">Boston Pharma</div> -->
            </div>
            <div class="col col--sm--12 col--md--6">
                <h4 class="h4 font--roboto font-weight--400 mb--sm--9 mt--sm--0 forTabletUp">Đăng nhập</h4>
                <form @submit.prevent="formSubmitHandle">
                  <HdInput
                    v-model="username"
                    type="text"
                    label="Tài khoản đăng nhập:"
                    placeholder="Tài khoản đăng nhập"
                    required
                  >
                    <template v-slot:icon>
                      <InputUserNameIcon/>
                    </template>
                  </HdInput>
                  <HdInput
                    v-model="password"
                    type="password"
                    label="Nhập mật khẩu:"
                    placeholder="Mật khẩu"
                    required
                  >
                    <template v-slot:icon>
                      <InputPasswordIcon/>
                    </template>
                  </HdInput>
                  <div class="text--sm--right mb--sm--9">
                    <router-link to="/forgot-password">Quên mật khẩu?</router-link>
                  </div>
                  <HdButton nativeType="submit" fullWidth>Đăng nhập</HdButton>
                </form>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
// Import Components
import HdInput from '@/components/HdInput'
import HdButton from '@/components/HdButton'

// Import icons
import InputPasswordIcon from '@/assets/input-password-icon.svg'
import InputUserNameIcon from '@/assets/input-user-name-icon.svg'
import HeaderLogo from '@/assets/header-logo.svg'

export default {
  components: {
    HdInput,
    HdButton,
    HeaderLogo,
    InputPasswordIcon,
    InputUserNameIcon
  },
  data () {
    return {
      username: '',
      password: '',
      role: 'BH',
      versionApp: 'web',
      deviceID: this.$store.getters['auth/getDeviceId']
    }
  },
  methods: {
    formSubmitHandle: function (event) {
      // eslint-disable-next-line camelcase
      const { username, password, role, versionApp, deviceID } = this
      this.$store.dispatch('auth/logIn', { username, password, role, versionApp, deviceID })
        .then(response => {
          this.$notify({
            duration: 1000,
            title: 'Thành công!',
            message: this.$t('login.success'),
            type: 'success'
          })
          this.$router.push('/').catch(() => {})
        }, error => {
          if (error && error.response) {
            this.$notify.error({
              duration: 1000,
              title: 'Lỗi!',
              message: error.response.data.error.message.value || error.message
            })
          }
        })
    }
  }
}
</script>
