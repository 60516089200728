<template>
    <div class="hd-input-wrapper" :class="[
      type === 'textarea' ? 'hd-textarea' : {
        'hd-input-wrapper--has-helpText': helpText
      }
    ]">
        <div v-if="label" class="hd-input__label">{{label}}</div>
        <div v-if="type !== 'textarea'" class="hd-input" :class="[{
          'hd-input--icon': $slots.icon
        }]">
          <i class="hd-input__icon"
            v-if="$slots.icon">
            <slot name="icon"></slot>
          </i>
          <input
              class="hd-input__inner"
              v-bind="$attrs"
              :type="type"
              :value="value"
              @input="handleInput"
              @focus="handleFocus"
              @blur="handleBlur"
              @change="handleChange"
              @keyup="handleKeyup"
              @click="handleClick"
          >
        </div>
        <small v-if="helpText" class="hd-input__help-text">{{helpText}}</small>
    </div>
</template>
<script>
export default {
  name: 'HdInput',
  inheritAttrs: false,
  data () {
    return {
    }
  },
  props: {
    value: String,
    label: String,
    icon: String,
    autocomplete: {
      type: String,
      default: 'off'
    },
    helpText: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    disabled: Boolean,
    readonly: Boolean
  },
  computed: {},
  methods: {
    handleKeyup (event) {
      this.$emit('keyup', event)
    },
    handleInput (event) {
      this.$emit('input', event.target.value)
    },
    handleFocus (event) {
      this.$emit('focus', event)
    },
    handleBlur (event) {
      this.$emit('blur', event)
    },
    handleClick (event) {
      this.$emit('click', event)
    },
    handleChange (event) {
      this.$emit('change', event.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
  .hd-input-wrapper {
    margin-bottom: 24px;
    &.hd-input-wrapper--has-helpText {
      margin-bottom: 12px;
    }
  }
  .hd-input__label {
    font-family: "SF Pro Display", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.75;
    margin-bottom: 4px;
    color: #003B35;
  }
  .hd-input {
    position: relative;
  }
  .hd-input__inner {
    width: 100%;
    height: 40px;
    font-family: "SF Pro Display", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 38px;
    padding: 0 16px;
    border: 1px solid #003B35;
    border-radius: 15px;
    color: #003B35;
    background-color: #FBFBFB;
    &:focus {
      background-color: #DEF4F2;
    }
    &::placeholder {
      color: #003B35;
    }
    .hd-input--icon &{
      padding-left: 46px;
    }
  }
  .hd-input__icon {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
  .hd-input__help-text {
    font-size: 12px;
    font-weight: 500;
    line-height: 2.3;
    text-align: right;
    display: block;
  }
</style>
